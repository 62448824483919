@import 'sprite';

// General -> Sprites
@include sprites($spritesheet-sprites);

// Personal -> Order -> Make (Sale order ajax)
.sale-order-ajax-v1 {
  margin: 40px 0 0 0;

  #bx-soa-order {
    /**/

    // Personal -> Order -> Make (Sale order ajax) -> General
    input[type='text'],
    input[type='password'],
    input[type='tel'],
    input[type='email'],
    textarea.form-control {
      border: 1px solid #E2DFDF;
      border-radius: 0;
      background: transparent;
      height: 45px;
      font-size: 14px;
      line-height: 20px;
      padding: 17px 12px;

      &:focus {
        border: 1px solid #ccc;
      }

      &::placeholder {
        color: #ABABAB;
        //color: red;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }

    textarea.form-control {
      resize: vertical;
      padding: 14px 12px 5px;
      min-height: 100px;
      border: 1px solid #E2DFDF !important;

      &:focus {
        border: 1px solid #ccc;
      }

      &::placeholder {
        /**/
      }

      &:focus::placeholder {
        /**/
      }
    }

    input[type="checkbox"].custom-checkbox {
      display: block;
      width: 0;
      line-height: 0;
      visibility: hidden;
      margin: 0;
    }

    input[type='checkbox'].custom-checkbox {
      /**/

      & + label {
        padding: 0 0 0 50px;
        font-size: 13px;
        color: #999;

        //margin-bottom: 11px;
        //padding-left: 5px;
        position: relative;
        z-index: 100;
        cursor: pointer;
        outline: none;
        font-weight: normal;
        margin: 0 0 11px;
        display: block;

        &:before {
          display: block;
          //top: 4px;
          top: 0;
          height: 18px;
          width: 33px;
          border-radius: 10px;
          color: #fff;
          background: #A8D036 !important;

          font-size: 0;
          content: ".";
          position: absolute;
          left: 1px;
          //background: #ddd;
          z-index: -1;
        }

        &:after {
          position: absolute;
          display: block;
          //top: 4px;
          top: 0;
          bottom: 0;
          border-radius: 100%;
          background: #fff;
          width: 14px;
          height: 14px;
          border: none;
          margin: 2px;
          left: 1px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
          transition: left .1s ease-in;
          z-index: 11;

          font-size: 0;
          content: ".";
        }
      }


      &:not(:checked) {
        /**/

        & + label {
          /**/

          &:before {
            display: block;
            //top: 4px;
            top: 0;
            height: 18px;
            width: 33px;
            border-radius: 10px;

            background: #e0e0e0 !important;
          }
        }
      }

      &:checked {
        /**/

        & + label {
          /**/

          &:after {
            left: 16px;
          }
        }
      }

    }

    label.error {
      color: #e62222 !important;
      font-size: 10px;
      position: absolute;
      top: 4px;
      right: 0;
      font-weight: 400;
    }

   .form {
     .agreement_offer_wrap {
       display: inline-block;
     }
    }

    &.orderform--v1 {
      .bx-soa-pp-company-graf-container {
        width: 16px;
        height: 16px;
        margin-top: 3px;
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company.bx-selected
      .bx-soa-pp-company-graf-container::after {
        left: 4px;
        top: 4px;
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-graf-container:before {
        border: 1px solid #CBCBCB;
        background: rgba(203, 203, 203, 0.25);
      }

      .bx-soa-pp-item-container .bx-soa-pp-company {
        padding: 0 0 0 46px;
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company:not(:first-of-type) {
        margin-top: 19px;
        margin-bottom: 19px;
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-delivery-cost
      .bx-soa-pp-list-description {
        font-size: 12px;
        line-height: 17px;
        padding: 3px 7px;
      }
    }

    .radio-inline.checked label:before,
    .radio-inline label:hover:before,
    .bx-soa-pp-company:hover .bx-soa-pp-company-graf-container:before,
    .bx-soa-pp-company.bx-selected .bx-soa-pp-company-graf-container:before {
      background-color: #A8D036;
    }

    .bx-soa-pp-item-container
    .bx-soa-pp-company-graf-container:before {
      width: 16px;
      height: 16px;
    }

    .bx-soa-pp-item-container
    .bx-soa-pp-company.bx-selected
    .bx-soa-pp-company-graf-container:after {
      width: 8px;
      height: 8px;
      //left: 4px;
      //top: 4px;
      background: #fff;
    }

    .bx-soa-pp-item-container
    .bx-soa-pp-company:hover
    .bx-soa-pp-company-smalltitle,
    .bx-soa-more
    .bx-soa-more-btn
    .pull-left {
      color: #a8d036;
    }

    .bx-soa-pp-company-description {

    }

    .bx-soa-pp-item-container
    .bx-soa-pp-company-smalltitle {
      font-size: 14px;
      line-height: 19px;
      margin: 0 0 3px 0 !important;
    }

    div[class*=bx-sls] {
      /**/

      .form-control {
        /**/

        &.dropdown-block {
          background-color: transparent;
          border: 1px solid #e2dfdf;
          height: 45px;
          padding-left: 56px;
          box-shadow: none;

          &:hover {
            /**/

            .bx-ui-sls-clear {
              /**/

              &:before {
                background-color: #a8d036 !important;
              }

              &:after {
                background-color: #a8d036 !important;
              }
            }

          }
        }


      }


      .dropdown-block {
        background-color: transparent;
        border: 1px solid #e2dfdf;
        height: 45px;
        padding-left: 56px;
        box-shadow: none;

        &:hover {
          /**/

          .bx-ui-sls-clear {
            /**/

            &:before {
              background-color: #a8d036 !important;
            }

            &:after {
              background-color: #a8d036 !important;
            }
          }

        }


      }

      .bx-ui-sls-clear {
        /**/

        &:hover {
          /**/

          &:before {
            background-color: #a8d036 !important;
          }

          &:after {
            background-color: #a8d036 !important;
          }
        }
      }

      &.bx-sls {
        /**/

        .bx-ui-sls-container {
          width: calc(100% - 27px);
          min-height: 43px;
        }
      }

    }

    .bx-soa-tooltip {
      bottom: auto;
      position: static;
      text-align: left;
      font-size: 12px;
      left: auto;
      margin: 7px 0 0 0;

      .tooltip-inner {
        text-align: left;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .bx-soa {
      /**/

      .bx-soa-section {
        border: 1px solid #E2DFDF;
        border-radius: 0;
        margin: 0 0 30px;

        .bx-soa-section-title-container {
          padding: 42px 58px 3px 88px !important;
          margin: 0 0 35px 0;

          .bx-soa-section-title {
            text-transform: none;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            width: 100%;
            padding: 0;
            color: #333;

            &:before {
              background-color: transparent;
              background-repeat: no-repeat;
            }

            &.bx-soa-section-title-2 {
              /**/

              &:before {
                background-color: #A8D036;
              }
            }
          }
        }

        .bx-soa-section-content {
          padding: 0 58px 42px 38px;

          .row {
            margin: 0;

            & > div {
              padding: 0;
            }
          }

        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Properties
      #bx-soa-properties {
        /**/

        &.bx-soa-section {
          /**/

          .bx-soa-properties-header {
            margin: 40px 58px 0 38px;

            .bx-soa-properties-header-cols {
              display: flex;
              flex-flow: row wrap;
              justify-content: flex-start;
              align-items: center;
              margin: 0 0 32px 0;

              .bx-soa-properties-header-cols-left {
                width: 325px;
                margin: 0 30px 0 0;
              }

              .bx-soa-properties-header-cols-right {
                text-align: right;
                margin: 4px 0 0 0;

                width: calc(100% - 355px);
              }
            }
          }

          .bx-soa-section-title-container {
            padding: 3px 0 3px 51px !important;
            margin: 0;

            .bx-soa-section-title {
              min-height: 30px;

              &:before {
                left: -51px;
                background-image: url("/local/templates/main2020/custom/img/sprites/img/dest/sprite.png");
                background-position: -79px 0;
                width: 37px;
                height: 37px;
              }
            }
          }

          .bx-soa-customer {
            width: 100%;
          }

          .form-group {
            margin: 14px 0 0;

            &.has-error {
              margin: 14px 0 0 0 !important;
            }

            .soa-property-container {
              position: relative;

              &:focus,
              &:active {
                //background: red;

                label {
                  display: none;
                }
              }

              .form-control {
                /**/

                &.active {
                  /**/

                  + label {
                    display: none;
                  }
                }
              }
            }

            &:first-child {
              margin: 0;
            }

            input[type='text'],
            input[type='password'],
            input[type='tel'],
            input[type='email'],
            textarea.form-control {
              /**/

              &::placeholder {
                color: #ABABAB;
                //color: red;
              }

              &:focus {
                border: 1px solid #ccc;

                &::placeholder {
                  color: transparent;
                }

                + label {
                  display: none;
                }
              }

              &:active {
                /**/

                + label {
                  display: none;
                }
              }

              & + label {
                display: inline;
                top: 13px;
                left: 14px;
                color: #ababab;
                margin: 0;
                font-size: 14px;
                position: absolute;

                &:focus,
                &:active {
                  display: none;
                }

                span {
                  color: #F11716;
                  font-weight: 700;
                }
              }

              &.not-empty {
                /**/

                + label {
                  display: none;
                }
              }


            }

            textarea {
              min-height: 190px;
              //border: 1px solid #E2DFDF;
              //border-radius: 0;
              //background: transparent;
            }
          }
        }

      }

      // Personal -> Order -> Make (Sale order ajax) -> Delivery
      #bx-soa-delivery {
        /**/

        &.bx-soa-section {
          /**/

          .bx-soa-section-title-container {
            padding: 42px 58px 3px 97px !important;
            margin: 0 0 16px 0;

            .bx-soa-section-title {
              min-height: 37px;

              &:before {
                left: -59px;
                background-image: url("/local/templates/main2020/custom/img/sprites/img/dest/sprite.png");
                background-position: 0 -37px;
                width: 40px;
                height: 35px;
              }
            }
          }

          .bx-soa-pp-company-description {
            //margin: 8px 0 0 0;
            margin: 0;
            width: auto;
            font-size: 12px;
            line-height: 18px;

            a {
              color: #a8d036;

              &:hover {
                color: #a8d036;
              }
            }
          }

          .bx-soa-section-content {
            padding: 0 38px 42px 38px;
          }
        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Paysystems
      #bx-soa-paysystem {
        /**/

        &.bx-soa-section {
          /**/

          .bx-soa-section-title-container {
            padding: 42px 58px 3px 98px !important;
            margin: 0 0 16px 0;

            .bx-soa-section-title {
              min-height: 37px;

              &:before {
                left: -61px;
                background-image: url("/local/templates/main2020/custom/img/sprites/img/dest/sprite.png");
                background-position: 0 0;
                width: 40px;
                height: 37px;
              }
            }
          }

          .bx-soa-pp-company-description {
            //margin: 8px 0 0 0;
            margin: 0;
            width: auto;
            font-size: 12px;
            line-height: 18px;

            a {
              color: #a8d036;

              &:hover {
                color: #a8d036;
              }
            }
          }

          .bx-soa-section-content {
            padding: 0 38px 42px 38px;

            .row {
              /**/

              &.bx-soa-pp {
                display: block;
              }
            }
          }


        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Basket
      #bx-soa-basket {
        margin: 0;

        &.bx-soa-section {
          /**/

          .bx-soa-section-title-container {
            padding: 42px 58px 3px 98px !important;
            margin: 0 0 55px 0;

            .bx-soa-section-title {
              min-height: 37px;

              &:before {
                left: -61px;
                background-image: url("/local/templates/main2020/custom/img/sprites/img/dest/sprite.png");
                background-position: -40px 0;
                width: 39px;
                height: 36px;
              }
            }
          }

          .bx-soa-pp-company-description {
            //margin: 8px 0 0 0;
            margin: 0;
            width: auto;
            font-size: 12px;
            line-height: 18px;

            a {
              color: #a8d036;

              &:hover {
                color: #a8d036;
              }
            }
          }

          .bx-soa-section-content {
            //padding: 0 0 42px;
            padding: 0;
            border: none;
          }

          .bx-soa-item-table {
            font-size: 14px;

            .bx-soa-item-tr {
              /**/

              &.bx-soa-item-tr-first {
                /**/
              }

              .bx-soa-item-td {
                /**/

                .bx-soa-item-title {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;

                  a {
                    text-decoration: underline;

                    &:hover {
                      text-decoration: none;
                    }
                  }
                }

                .bx-price {
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }

          .bx-soa-coupon {
            /**/

            .bx-soa-coupon-item {
              /**/

              .bx-soa-coupon-remove {
                /**/

                &:before,
                &:after {
                  /**/
                }

                &:hover {
                  /**/

                  &:before,
                  &:after {
                    background-color: #a8d036 !important;
                  }
                }
              }


            }
          }

        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Region
      #bx-soa-region {
        border: none;
        margin: 0;
        padding: 0;

        &.bx-soa-section {
          /**/

          .alert {
            display: none;
          }

          .bx-soa-section-title-container {
            /**/

            .bx-soa-section-title {
              /**/

              &:before {
                /**/
              }
            }
          }

          .bx-soa-section-content {
            padding: 0;
          }

          .form-group {
            /**/

            .radio-inline {
              padding: 5px 0 5px 0;

              label {
                color: #333;
              }

              &:first-child {
                padding: 5px 40px 5px 0;
              }

              &.checked {

                label {

                  &:before {
                    background-color: #a8d036;
                    width: 16px;
                    height: 16px;
                    margin: 3px 0 0 0;
                  }

                  &:after {
                    left: 4px;
                    top: 7px;
                    width: 8px;
                    height: 8px;
                  }
                }
              }

              label {
                font-size: 14px;
                line-height: 19px;
                padding: 0 0 0 36px !important;
                cursor: pointer;

                &:before {
                  border: 1px solid #CBCBCB;
                  background: rgba(203, 203, 203, 0.25);
                  width: 16px;
                  height: 16px;
                  margin: 3px 0 0 0;
                }

                &:hover {
                  /**/

                  &:before {
                    background-color: #a8d036;
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }

            &:after {
              display: none;
            }

          }


        }

      }

      // Personal -> Order -> Make (Sale order ajax) -> Bottom
      .soa-bottom {
        width: 100%;
        border: 1px solid #e2dfdf;
        border-top: none;
        border-radius: 0;
        margin: 0;
        padding: 37px 31px 37px 31px;

        .soa-bottom-cols {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: center;

          .soa-bottom-cols-left {
            //width: 530px;
            //margin: 0 45px 0 0;
            width: calc(100% - 255px);
          }

          .soa-bottom-cols-right {
            //width: calc(100% - 575px);
            width: 215px;
            margin: 0 0 0 40px;
          }
        }

        .form {
          margin: 0;
          text-align: left;

          .licence_block {
            margin: 0;
            padding: 0;

            //padding: 0 0 26px;
            position: relative;
            //margin: -10px 0px 0px;

            text-align: left;

            label {
              //font-size: 10px;
              font-size: 12px;

              &.license {
                margin: -5px 0 0 0;
                //font-size: 10px;
                //line-height: 14px;
                font-size: 12px;
                line-height: 17px;
                padding: 0 0 0 60px;

                a {
                  color: #A8D036;
                  text-decoration: none;
                  outline: none;
                  border: none;

                  font-weight: 700;
                  line-height: 17px;
                  font-size: 12px;

                  &:hover {
                    color: #A8D036;
                    text-decoration: underline;
                    outline: none;
                    border: none;
                  }
                }
              }
            }

            .error {
              position: absolute;
              top: -3px;
            }
          }

          .agreement_offer_block {
            margin: 0;
            padding: 0;

            //padding: 0 0 26px;
            position: relative;
            //margin: -10px 0px 0px;

            text-align: left;

            label {
              //font-size: 10px;
              font-size: 12px;

              &.agreement_offer {
                margin: -5px 0 0 0;
                //font-size: 10px;
                //line-height: 14px;
                font-size: 12px;
                line-height: 17px;
                padding: 0 0 0 60px;

                a {
                  color: #A8D036;
                  text-decoration: none;
                  outline: none;
                  border: none;

                  font-weight: 700;
                  line-height: 17px;
                  font-size: 12px;

                  &:hover {
                    color: #A8D036;
                    text-decoration: underline;
                    outline: none;
                    border: none;
                  }
                }
              }
            }

            .error {
              position: absolute;
              top: -3px;
            }
          }
        }

      }

      #bx-soa-orderSave {
        margin: 0;
        text-align: right;

        .btn-default {
          background: #A8D036 !important;
          font-size: 14px;
          text-transform: uppercase;
          border: none;
          //box-shadow: none;
          border-radius: 0;
          padding: 12px 38px;
          height: auto;
          line-height: 19px;
          margin: 0 !important;
          white-space: normal;
          vertical-align: top;
          font-weight: 400;
          color: #fff !important;

          &.disabled {
            opacity: 0.5;
            cursor: default;
          }

          &:hover {
            /**/
          }
        }

      }
    }

    // Personal -> Order -> Make (Sale order ajax) -> Total
    #bx-soa-total {
      color: #262626;

      .bx-soa-total-title {
        padding: 0 27px;
        color: #262626;
        font-size: 22px;
        line-height: 30px;
        margin: 0 0 21px 0;
      }

      .bx-soa-cart-total {
        border-radius: 0;
        padding: 42px 0;
        border: 1px solid #D0D8A9;

        .bx-soa-cart-total-line {
          color: #262626;
          font-size: 12px;
          line-height: 17px;
          margin: 15px 0 0;
          padding: 0 27px;

          &:before {
            display: none;
          }

          .bx-soa-cart-t {
            padding: 0;
            color: #262626;
            font-weight: 400;
          }

          .bx-soa-cart-d {
            color: #262626;
          }

          &.bx-soa-cart-total-line-total {
            border-top: 1px solid #D0D8A9;
            padding: 15px 27px 0;
            margin: 25px 0 21px 0;

            .bx-soa-cart-t {
              padding: 0;
              color: #262626;
              font-weight: 400;
            }

            .bx-soa-cart-d {
              font-size: 22px !important;
              font-weight: 400;
              line-height: 30px;
              padding: 0;
              color: #262626;
            }
          }

          &.bx-soa-cart-total-line-highlighted {
            /**/

            .bx-soa-cart-d {
              background: none;
              border: none;
              border-radius: 0;
              color: #F11716;
              padding: 0 0 0 12px;
            }
          }

        }
      }

      .bx-soa-cart-total-button-container {
        margin: 0;
        padding: 0 27px;

        .btn-default {
          background: #a8d036 !important;
          font-size: 14px !important;
          text-transform: uppercase;
          border: none;
          border-radius: 0;
          padding: 12px 32px;
          height: auto;
          line-height: 19px;
          margin: 0 !important;
          white-space: normal;
          vertical-align: top;
          font-weight: 400;
          color: #fff !important;

          &:hover {
            /**/
          }
        }
      }
    }

    #bx-soa-total-mobile {
      margin: 33px 0 20px 0 !important;
      color: #262626;

      .bx-soa-total-title {
        padding: 0 27px;
        color: #262626;
        font-size: 22px;
        line-height: 30px;
        margin: 0 0 21px 0;
      }

      .bx-soa-cart-total {
        border-radius: 0;
        padding: 42px 0;
        border: 1px solid #D0D8A9;

        .bx-soa-cart-total-line {
          color: #262626;
          font-size: 12px;
          line-height: 17px;
          margin: 15px 0 0;
          padding: 0 27px;

          &:before {
            display: none;
          }

          .bx-soa-cart-t {
            padding: 0;
            color: #262626;
            font-weight: 400;
          }

          .bx-soa-cart-d {
            color: #262626;
          }

          &.bx-soa-cart-total-line-total {
            border-top: 1px solid #D0D8A9;
            padding: 15px 27px 0;
            margin: 25px 0 21px 0;

            .bx-soa-cart-t {
              padding: 0;
              color: #262626;
              font-weight: 400;
            }

            .bx-soa-cart-d {
              font-size: 22px !important;
              font-weight: 400;
              line-height: 30px;
              padding: 0;
              color: #262626;
            }
          }

          &.bx-soa-cart-total-line-highlighted {
            /**/

            .bx-soa-cart-d {
              background: none;
              border: none;
              border-radius: 0;
              color: #F11716;
              padding: 0 0 0 12px;
            }
          }
        }
      }

      .bx-soa-cart-total-button-container {
        margin: 0;
        padding: 0 27px;

        .btn-default {
          background: #a8d036 !important;
          font-size: 14px !important;
          text-transform: uppercase;
          border: none;
          border-radius: 0;
          padding: 12px 32px;
          height: auto;
          line-height: 19px;
          margin: 0 !important;
          white-space: normal;
          vertical-align: top;
          font-weight: 400;
          color: #fff !important;

          &:hover {
            /**/
          }
        }
      }
    }

    // Personal -> Order -> Make (Sale order ajax) -> Pick up
    #bx-soa-pickup {
      /**/

      .bx-soa-pickup-list {
        /**/

        .bx-soa-pickup-l-item-detail {
          margin-top: 39px;
        }

        .bx-soa-pickup-l-item-btn {
          /**/

          .btn {
            /**/

            &.btn-default {
              /**/

              background: #a8d036 !important;
              font-size: 14px;
              text-transform: uppercase;
              border: none;
              border-radius: 0;
              padding: 12px 38px;
              height: auto;
              line-height: 19px;
              margin: 0 !important;
              white-space: normal;
              vertical-align: top;
              font-weight: 400;
              color: #fff !important;
              position: relative;
              flex-flow: row wrap;
              align-items: center;

              &:hover {
                /**/
              }
            }
          }

        }
      }


    }
  }
}

@media (max-width: 1199px) {
  .sale-order-ajax-v1 {
    /**/

    #bx-soa-order {
      /**/

      // Personal -> Order -> Make (Sale order ajax) -> General
      input[type='text'],
      input[type='password'],
      input[type='tel'],
      input[type='email'],
      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      input[type="checkbox"].custom-checkbox {
        /**/
      }

      input[type='checkbox'].custom-checkbox {
        /**/

        & + label {
          /**/

          &:before {
            /**/
          }

          &:after {
            /**/
          }
        }


        &:not(:checked) {
          /**/

          & + label {
            /**/

            &:before {
              /**/
            }
          }
        }

        &:checked {
          /**/

          & + label {
            /**/

            &:after {
              /**/
            }
          }
        }

      }

      label.error {
        /**/
      }

      &.orderform--v1 {
        .bx-soa-pp-company-graf-container {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company.bx-selected
        .bx-soa-pp-company-graf-container::after {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company-graf-container:before {
          /**/
        }

        .bx-soa-pp-item-container .bx-soa-pp-company {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company:not(:first-of-type) {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-delivery-cost
        .bx-soa-pp-list-description {
          /**/
        }
      }

      .radio-inline.checked label:before,
      .radio-inline label:hover:before,
      .bx-soa-pp-company:hover .bx-soa-pp-company-graf-container:before,
      .bx-soa-pp-company.bx-selected .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company.bx-selected
      .bx-soa-pp-company-graf-container:after {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company:hover
      .bx-soa-pp-company-smalltitle,
      .bx-soa-more
      .bx-soa-more-btn
      .pull-left {
        /**/
      }

      .bx-soa-pp-company-description {

      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-smalltitle {
        /**/
      }

      div[class*=bx-sls] {
        /**/

        .form-control {
          /**/

          &.dropdown-block {
            /**/

            &:hover {
              /**/

              .bx-ui-sls-clear {
                /**/

                &:before {
                  /**/
                }

                &:after {
                  /**/
                }
              }

            }
          }


        }


        .dropdown-block {
          /**/

          &:hover {
            /**/

            .bx-ui-sls-clear {
              /**/

              &:before {
                /**/
              }

              &:after {
                /**/
              }
            }

          }


        }

        .bx-ui-sls-clear {
          /**/

          &:hover {
            /**/

            &:before {
              /**/
            }

            &:after {
              /**/
            }
          }
        }

        &.bx-sls {
          /**/

          .bx-ui-sls-container {
            /**/
          }
        }

      }

      .bx-soa-tooltip {
        /**/

        .tooltip-inner {
          /**/
        }
      }

      .bx-soa {
        /**/

        .bx-soa-section {
          /**/

          .bx-soa-section-title-container {
            /**/

            .bx-soa-section-title {
              /**/

              &:before {
                /**/
              }

              &.bx-soa-section-title-2 {
                /**/

                &:before {
                  /**/
                }
              }
            }
          }

          .bx-soa-section-content {
            /**/

            .row {
              /**/

              & > div {
                /**/
              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Properties
        #bx-soa-properties {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-properties-header {
              /**/

              .bx-soa-properties-header-cols {
                /**/

                .bx-soa-properties-header-cols-left {
                  /**/
                }

                .bx-soa-properties-header-cols-right {
                  text-align: left;
                }
              }
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .form-group {
              /**/

              &.has-error {
                /**/
              }

              .soa-property-container {
                /**/

                &:focus,
                &:active {
                  /**/

                  label {
                    /**/
                  }
                }

                .form-control {
                  /**/

                  &.active {
                    /**/

                    + label {
                      /**/
                    }
                  }
                }
              }

              &:first-child {
                /**/
              }

              input[type='text'],
              input[type='password'],
              input[type='tel'],
              input[type='email'],
              textarea.form-control {
                /**/

                &::placeholder {
                  /**/
                }

                &:focus {
                  /**/

                  &::placeholder {
                    /**/
                  }

                  + label {
                    /**/
                  }
                }

                &:active {
                  /**/

                  + label {
                    /**/
                  }
                }

                & + label {
                  /**/

                  &:focus,
                  &:active {
                    /**/
                  }

                  span {
                    /**/
                  }
                }

                &.not-empty {
                  /**/

                  + label {
                    /**/
                  }
                }


              }

              textarea {
                /**/
              }
            }
          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Delivery
        #bx-soa-delivery {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }
          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Paysystems
        #bx-soa-paysystem {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/

              .row {
                /**/

                &.bx-soa-pp {
                  /**/
                }
              }
            }


          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Basket
        #bx-soa-basket {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .bx-soa-item-table {
              /**/

              .bx-soa-item-tr {
                /**/

                &.bx-soa-item-tr-first {
                  /**/
                }

                .bx-soa-item-td {
                  /**/

                  .bx-soa-item-title {
                    /**/

                    a {
                      /**/

                      &:hover {
                        /**/
                      }
                    }
                  }

                  .bx-price {
                    /**/
                  }
                }
              }
            }

            .bx-soa-coupon {
              /**/

              .bx-soa-coupon-item {
                /**/

                .bx-soa-coupon-remove {
                  /**/

                  &:before,
                  &:after {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before,
                    &:after {
                      /**/
                    }
                  }
                }


              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Region
        #bx-soa-region {
          /**/

          &.bx-soa-section {
            /**/

            .alert {
              /**/
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .form-group {
              /**/

              .radio-inline {
                margin: 0;

                label {
                  /**/
                }

                &:first-child {
                  padding: 5px 0;
                }

                &.checked {

                  label {

                    &:before {
                      /**/
                    }

                    &:after {
                      /**/
                    }
                  }
                }

                label {
                  /**/

                  &:before {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before {
                      /**/
                    }
                  }
                }
              }

              &:after {
                /**/
              }

            }


          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Bottom
        .soa-bottom {
          /**/

          .soa-bottom-cols {
            /**/

            .soa-bottom-cols-left {
              /**/
            }

            .soa-bottom-cols-right {
              /**/
            }
          }

          .form {
            /**/

            .licence_block {
              /**/

              label {
                /**/

                &.license {
                  /**/

                  a {
                    /**/

                    &:hover {
                      /**/
                    }
                  }
                }
              }

              .error {
                /**/
              }
            }
          }

        }

        #bx-soa-orderSave {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }

        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Total
      #bx-soa-total {
        /**/

        .bx-soa-total-title {
          /**/
        }

        .bx-soa-cart-total {
          /**/

          .bx-soa-cart-total-line {
            /**/

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      #bx-soa-total-mobile {
        /**/

        .bx-soa-total-title {
          /**/
        }

        .bx-soa-cart-total {
          /**/

          .bx-soa-cart-total-line {
            /**/

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Pick up
      #bx-soa-pickup {
        /**/

        .bx-soa-pickup-list {
          /**/

          .bx-soa-pickup-l-item-detail {
            /**/
          }

          .bx-soa-pickup-l-item-btn {
            /**/

            .btn {
              /**/

              &.btn-default {
                /**/

                &:hover {
                  /**/
                }
              }
            }

          }
        }


      }
    }
  }
}

@media (max-width: 992px) {
  .sale-order-ajax-v1 {
    /**/

    #bx-soa-order {
      /**/

      // Personal -> Order -> Make (Sale order ajax) -> General
      input[type='text'],
      input[type='password'],
      input[type='tel'],
      input[type='email'],
      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      input[type="checkbox"].custom-checkbox {
        /**/
      }

      input[type='checkbox'].custom-checkbox {
        /**/

        & + label {
          /**/

          &:before {
            /**/
          }

          &:after {
            /**/
          }
        }


        &:not(:checked) {
          /**/

          & + label {
            /**/

            &:before {
              /**/
            }
          }
        }

        &:checked {
          /**/

          & + label {
            /**/

            &:after {
              /**/
            }
          }
        }

      }

      label.error {
        /**/
      }

      &.orderform--v1 {
        .bx-soa-pp-company-graf-container {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company.bx-selected
        .bx-soa-pp-company-graf-container::after {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company-graf-container:before {
          /**/
        }

        .bx-soa-pp-item-container .bx-soa-pp-company {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company:not(:first-of-type) {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-delivery-cost
        .bx-soa-pp-list-description {
          /**/
        }
      }

      .radio-inline.checked label:before,
      .radio-inline label:hover:before,
      .bx-soa-pp-company:hover .bx-soa-pp-company-graf-container:before,
      .bx-soa-pp-company.bx-selected .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company.bx-selected
      .bx-soa-pp-company-graf-container:after {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company:hover
      .bx-soa-pp-company-smalltitle,
      .bx-soa-more
      .bx-soa-more-btn
      .pull-left {
        /**/
      }

      .bx-soa-pp-company-description {

      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-smalltitle {
        /**/
      }

      div[class*=bx-sls] {
        /**/

        .form-control {
          /**/

          &.dropdown-block {
            /**/

            &:hover {
              /**/

              .bx-ui-sls-clear {
                /**/

                &:before {
                  /**/
                }

                &:after {
                  /**/
                }
              }

            }
          }


        }


        .dropdown-block {
          /**/

          &:hover {
            /**/

            .bx-ui-sls-clear {
              /**/

              &:before {
                /**/
              }

              &:after {
                /**/
              }
            }

          }


        }

        .bx-ui-sls-clear {
          /**/

          &:hover {
            /**/

            &:before {
              /**/
            }

            &:after {
              /**/
            }
          }
        }

        &.bx-sls {
          /**/

          .bx-ui-sls-container {
            /**/
          }
        }

      }

      .bx-soa-tooltip {
        /**/

        .tooltip-inner {
          /**/
        }
      }

      .bx-soa {
        /**/

        .bx-soa-section {
          /**/

          .bx-soa-section-title-container {
            /**/

            .bx-soa-section-title {
              /**/

              &:before {
                /**/
              }

              &.bx-soa-section-title-2 {
                /**/

                &:before {
                  /**/
                }
              }
            }
          }

          .bx-soa-section-content {
            /**/

            .row {
              /**/

              & > div {
                /**/
              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Properties
        #bx-soa-properties {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-properties-header {
              /**/

              .bx-soa-properties-header-cols {
                /**/

                .bx-soa-properties-header-cols-left {
                  /**/
                }

                .bx-soa-properties-header-cols-right {
                  text-align: left;
                }
              }
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .form-group {
              /**/

              &.has-error {
                /**/
              }

              .soa-property-container {
                /**/

                &:focus,
                &:active {
                  /**/

                  label {
                    /**/
                  }
                }

                .form-control {
                  /**/

                  &.active {
                    /**/

                    + label {
                      /**/
                    }
                  }
                }
              }

              &:first-child {
                /**/
              }

              input[type='text'],
              input[type='password'],
              input[type='tel'],
              input[type='email'],
              textarea.form-control {
                /**/

                &::placeholder {
                  /**/
                }

                &:focus {
                  /**/

                  &::placeholder {
                    /**/
                  }

                  + label {
                    /**/
                  }
                }

                &:active {
                  /**/

                  + label {
                    /**/
                  }
                }

                & + label {
                  /**/

                  &:focus,
                  &:active {
                    /**/
                  }

                  span {
                    /**/
                  }
                }

                &.not-empty {
                  /**/

                  + label {
                    /**/
                  }
                }


              }

              textarea {
                /**/
              }
            }
          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Delivery
        #bx-soa-delivery {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }
          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Paysystems
        #bx-soa-paysystem {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/

              .row {
                /**/

                &.bx-soa-pp {
                  /**/
                }
              }
            }


          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Basket
        #bx-soa-basket {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .bx-soa-item-table {
              /**/

              .bx-soa-item-tr {
                /**/

                &.bx-soa-item-tr-first {
                  /**/
                }

                .bx-soa-item-td {
                  /**/

                  .bx-soa-item-title {
                    /**/

                    a {
                      /**/

                      &:hover {
                        /**/
                      }
                    }
                  }

                  .bx-price {
                    /**/
                  }
                }
              }
            }

            .bx-soa-coupon {
              /**/

              .bx-soa-coupon-item {
                /**/

                .bx-soa-coupon-remove {
                  /**/

                  &:before,
                  &:after {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before,
                    &:after {
                      /**/
                    }
                  }
                }


              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Region
        #bx-soa-region {
          /**/

          &.bx-soa-section {
            /**/

            .alert {
              /**/
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .form-group {
              /**/

              .radio-inline {
                margin: 0;

                label {
                  /**/
                }

                &:first-child {
                  padding: 5px 0;
                }

                &.checked {

                  label {

                    &:before {
                      /**/
                    }

                    &:after {
                      /**/
                    }
                  }
                }

                label {
                  /**/

                  &:before {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before {
                      /**/
                    }
                  }
                }
              }

              &:after {
                /**/
              }

            }


          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Bottom
        .soa-bottom {
          /**/

          .soa-bottom-cols {
            /**/

            .soa-bottom-cols-left {
              /**/
            }

            .soa-bottom-cols-right {
              /**/
            }
          }

          .form {
            /**/

            .licence_block {
              /**/

              label {
                /**/

                &.license {
                  /**/

                  a {
                    /**/

                    &:hover {
                      /**/
                    }
                  }
                }
              }

              .error {
                /**/
              }
            }
          }

        }

        #bx-soa-orderSave {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }

        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Total
      #bx-soa-total {
        /**/

        .bx-soa-total-title {
          /**/
        }

        .bx-soa-cart-total {
          /**/

          .bx-soa-cart-total-line {
            /**/

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          display: block !important;

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      #bx-soa-total-mobile {
        /**/

        .bx-soa-total-title {
          /**/
        }

        .bx-soa-cart-total {
          /**/

          .bx-soa-cart-total-line {
            /**/

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          display: block !important;

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Pick up
      #bx-soa-pickup {
        /**/

        .bx-soa-pickup-list {
          /**/

          .bx-soa-pickup-l-item-detail {
            /**/
          }

          .bx-soa-pickup-l-item-btn {
            /**/

            .btn {
              /**/

              &.btn-default {
                /**/

                &:hover {
                  /**/
                }
              }
            }

          }
        }


      }
    }
  }
}

@media (max-width: 767px) {
  .sale-order-ajax-v1 {
    /**/

    #bx-soa-order {
      /**/

      // Personal -> Order -> Make (Sale order ajax) -> General
      input[type='text'],
      input[type='password'],
      input[type='tel'],
      input[type='email'],
      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      input[type="checkbox"].custom-checkbox {
        /**/
      }

      input[type='checkbox'].custom-checkbox {
        /**/

        & + label {
          /**/

          &:before {
            /**/
          }

          &:after {
            /**/
          }
        }


        &:not(:checked) {
          /**/

          & + label {
            /**/

            &:before {
              /**/
            }
          }
        }

        &:checked {
          /**/

          & + label {
            /**/

            &:after {
              /**/
            }
          }
        }

      }

      label.error {
        /**/
      }

      &.orderform--v1 {
        .bx-soa-pp-company-graf-container {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company.bx-selected
        .bx-soa-pp-company-graf-container::after {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company-graf-container:before {
          /**/
        }

        .bx-soa-pp-item-container .bx-soa-pp-company {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company:not(:first-of-type) {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-delivery-cost
        .bx-soa-pp-list-description {
          /**/
        }
      }

      .radio-inline.checked label:before,
      .radio-inline label:hover:before,
      .bx-soa-pp-company:hover .bx-soa-pp-company-graf-container:before,
      .bx-soa-pp-company.bx-selected .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company.bx-selected
      .bx-soa-pp-company-graf-container:after {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company:hover
      .bx-soa-pp-company-smalltitle,
      .bx-soa-more
      .bx-soa-more-btn
      .pull-left {
        /**/
      }

      .bx-soa-pp-company-description {

      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-smalltitle {
        /**/
      }

      div[class*=bx-sls] {
        /**/

        .form-control {
          /**/

          &.dropdown-block {
            /**/

            &:hover {
              /**/

              .bx-ui-sls-clear {
                /**/

                &:before {
                  /**/
                }

                &:after {
                  /**/
                }
              }

            }
          }


        }


        .dropdown-block {
          /**/

          &:hover {
            /**/

            .bx-ui-sls-clear {
              /**/

              &:before {
                /**/
              }

              &:after {
                /**/
              }
            }

          }


        }

        .bx-ui-sls-clear {
          /**/

          &:hover {
            /**/

            &:before {
              /**/
            }

            &:after {
              /**/
            }
          }
        }

        &.bx-sls {
          /**/

          .bx-ui-sls-container {
            /**/
          }
        }

      }

      .bx-soa-tooltip {
        /**/

        .tooltip-inner {
          /**/
        }
      }

      .bx-soa {
        /**/

        .bx-soa-section {
          /**/

          .bx-soa-section-title-container {
            padding: 32px 58px 3px 74px;

            .bx-soa-section-title {
              /**/

              &:before {
                /**/
              }

              &.bx-soa-section-title-2 {
                /**/

                &:before {
                  /**/
                }
              }
            }
          }

          .bx-soa-section-content {
            padding: 0 15px 24px 15px;

            .row {
              /**/

              & > div {
                /**/
              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Properties
        #bx-soa-properties {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-properties-header {
              margin: 24px 15px 0 15px;

              .bx-soa-properties-header-cols {
                /**/

                .bx-soa-properties-header-cols-left {
                  /**/
                }

                .bx-soa-properties-header-cols-right {
                  /**/
                }
              }
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .form-group {
              /**/

              &.has-error {
                /**/
              }

              .soa-property-container {
                /**/

                &:focus,
                &:active {
                  /**/

                  label {
                    /**/
                  }
                }

                .form-control {
                  /**/

                  &.active {
                    /**/

                    + label {
                      /**/
                    }
                  }
                }
              }

              &:first-child {
                /**/
              }

              input[type='text'],
              input[type='password'],
              input[type='tel'],
              input[type='email'],
              textarea.form-control {
                /**/

                &::placeholder {
                  /**/
                }

                &:focus {
                  /**/

                  &::placeholder {
                    /**/
                  }

                  + label {
                    /**/
                  }
                }

                &:active {
                  /**/

                  + label {
                    /**/
                  }
                }

                & + label {
                  /**/

                  &:focus,
                  &:active {
                    /**/
                  }

                  span {
                    /**/
                  }
                }

                &.not-empty {
                  /**/

                  + label {
                    /**/
                  }
                }


              }

              textarea {
                /**/
              }
            }
          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Delivery
        #bx-soa-delivery {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              padding: 32px 58px 3px 74px !important;

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              padding: 0 15px 24px 15px;
            }
          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Paysystems
        #bx-soa-paysystem {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              padding: 32px 58px 3px 74px !important;

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              padding: 0 15px 24px 15px;

              .row {
                /**/

                &.bx-soa-pp {
                  /**/
                }
              }
            }


          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Basket
        #bx-soa-basket {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              padding: 32px 58px 3px 74px !important;
              margin: 0 0 5px 0;

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .bx-soa-item-table {
              /**/

              .bx-soa-item-tr {
                /**/

                &.bx-soa-item-tr-first {
                  /**/
                }

                .bx-soa-item-td {
                  /**/

                  .bx-soa-item-title {
                    /**/

                    a {
                      /**/

                      &:hover {
                        /**/
                      }
                    }
                  }

                  .bx-price {
                    /**/
                  }
                }
              }
            }

            .bx-soa-coupon {
              padding: 25px 15px 0 15px;
              margin: 0 0 24px;

              .bx-soa-coupon-item {
                /**/

                .bx-soa-coupon-remove {
                  /**/

                  &:before,
                  &:after {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before,
                    &:after {
                      /**/
                    }
                  }
                }


              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Region
        #bx-soa-region {
          /**/

          &.bx-soa-section {
            /**/

            .alert {
              /**/
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .form-group {
              /**/

              .radio-inline {
                display: block;

                label {
                  /**/
                }

                &:first-child {
                  /**/
                }

                &.checked {

                  label {

                    &:before {
                      /**/
                    }

                    &:after {
                      /**/
                    }
                  }
                }

                label {
                  /**/

                  &:before {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before {
                      /**/
                    }
                  }
                }
              }

              &:after {
                /**/
              }

            }


          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Bottom
        .soa-bottom {
          padding: 24px 15px 24px 15px;

          .soa-bottom-cols {
            /**/

            .soa-bottom-cols-left {
              /**/
            }

            .soa-bottom-cols-right {
              /**/
            }
          }

          .form {
            /**/

            .licence_block {
              /**/

              label {
                /**/

                &.license {
                  /**/

                  a {
                    /**/

                    &:hover {
                      /**/
                    }
                  }
                }
              }

              .error {
                /**/
              }
            }
          }

        }

        #bx-soa-orderSave {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }

        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Total
      #bx-soa-total {
        /**/

        .bx-soa-total-title {
          /**/
        }

        .bx-soa-cart-total {
          /**/

          .bx-soa-cart-total-line {
            /**/

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      #bx-soa-total-mobile {
        /**/

        .bx-soa-total-title {
          padding: 0 15px;
        }

        .bx-soa-cart-total {
          padding: 14px 0 24px;

          .bx-soa-cart-total-line {
            padding: 15px 15px 0;

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          padding: 0 15px;

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Pick up
      #bx-soa-pickup {
        /**/

        .bx-soa-section-title-container {
          padding: 32px 58px 3px 64px !important;
        }

        .bx-soa-pickup-list {
          /**/

          .bx-soa-pickup-l-item-detail {
            /**/
          }

          .bx-soa-pickup-l-item-btn {
            /**/

            .btn {
              /**/

              &.btn-default {
                /**/

                &:hover {
                  /**/
                }
              }
            }

          }
        }


      }
    }
  }
}

@media (max-width: 675px) {
  .sale-order-ajax-v1 {
    /**/

    #bx-soa-order {
      /**/

      // Personal -> Order -> Make (Sale order ajax) -> General
      input[type='text'],
      input[type='password'],
      input[type='tel'],
      input[type='email'],
      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      input[type="checkbox"].custom-checkbox {
        /**/
      }

      input[type='checkbox'].custom-checkbox {
        /**/

        & + label {
          /**/

          &:before {
            /**/
          }

          &:after {
            /**/
          }
        }


        &:not(:checked) {
          /**/

          & + label {
            /**/

            &:before {
              /**/
            }
          }
        }

        &:checked {
          /**/

          & + label {
            /**/

            &:after {
              /**/
            }
          }
        }

      }

      label.error {
        /**/
      }

      &.orderform--v1 {
        .bx-soa-pp-company-graf-container {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company.bx-selected
        .bx-soa-pp-company-graf-container::after {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company-graf-container:before {
          /**/
        }

        .bx-soa-pp-item-container .bx-soa-pp-company {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company:not(:first-of-type) {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-delivery-cost
        .bx-soa-pp-list-description {
          /**/
        }
      }

      .radio-inline.checked label:before,
      .radio-inline label:hover:before,
      .bx-soa-pp-company:hover .bx-soa-pp-company-graf-container:before,
      .bx-soa-pp-company.bx-selected .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company.bx-selected
      .bx-soa-pp-company-graf-container:after {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company:hover
      .bx-soa-pp-company-smalltitle,
      .bx-soa-more
      .bx-soa-more-btn
      .pull-left {
        /**/
      }

      .bx-soa-pp-company-description {

      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-smalltitle {
        /**/
      }

      div[class*=bx-sls] {
        /**/

        .form-control {
          /**/

          &.dropdown-block {
            /**/

            &:hover {
              /**/

              .bx-ui-sls-clear {
                /**/

                &:before {
                  /**/
                }

                &:after {
                  /**/
                }
              }

            }
          }


        }


        .dropdown-block {
          /**/

          &:hover {
            /**/

            .bx-ui-sls-clear {
              /**/

              &:before {
                /**/
              }

              &:after {
                /**/
              }
            }

          }


        }

        .bx-ui-sls-clear {
          /**/

          &:hover {
            /**/

            &:before {
              /**/
            }

            &:after {
              /**/
            }
          }
        }

        &.bx-sls {
          /**/

          .bx-ui-sls-container {
            /**/
          }
        }

      }

      .bx-soa-tooltip {
        /**/

        .tooltip-inner {
          /**/
        }
      }

      .bx-soa {
        /**/

        .bx-soa-section {
          /**/

          .bx-soa-section-title-container {
            /**/

            .bx-soa-section-title {
              /**/

              &:before {
                /**/
              }

              &.bx-soa-section-title-2 {
                /**/

                &:before {
                  /**/
                }
              }
            }
          }

          .bx-soa-section-content {
            /**/

            .row {
              /**/

              & > div {
                /**/
              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Properties
        #bx-soa-properties {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-properties-header {
              /**/

              .bx-soa-properties-header-cols {
                flex-flow: column;

                .bx-soa-properties-header-cols-left {
                  width: 100%;
                  margin: 0 0 20px 0;
                }

                .bx-soa-properties-header-cols-right {
                  width: 100%;
                }
              }
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .form-group {
              /**/

              &.has-error {
                /**/
              }

              .soa-property-container {
                /**/

                &:focus,
                &:active {
                  /**/

                  label {
                    /**/
                  }
                }

                .form-control {
                  /**/

                  &.active {
                    /**/

                    + label {
                      /**/
                    }
                  }
                }
              }

              &:first-child {
                /**/
              }

              input[type='text'],
              input[type='password'],
              input[type='tel'],
              input[type='email'],
              textarea.form-control {
                /**/

                &::placeholder {
                  /**/
                }

                &:focus {
                  /**/

                  &::placeholder {
                    /**/
                  }

                  + label {
                    /**/
                  }
                }

                &:active {
                  /**/

                  + label {
                    /**/
                  }
                }

                & + label {
                  /**/

                  &:focus,
                  &:active {
                    /**/
                  }

                  span {
                    /**/
                  }
                }

                &.not-empty {
                  /**/

                  + label {
                    /**/
                  }
                }


              }

              textarea {
                /**/
              }
            }
          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Delivery
        #bx-soa-delivery {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }
          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Paysystems
        #bx-soa-paysystem {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/

              .row {
                /**/

                &.bx-soa-pp {
                  /**/
                }
              }
            }


          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Basket
        #bx-soa-basket {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .bx-soa-item-table {
              /**/

              .bx-soa-item-tr {
                /**/

                &.bx-soa-item-tr-first {
                  /**/
                }

                .bx-soa-item-td {
                  /**/

                  .bx-soa-item-title {
                    /**/

                    a {
                      /**/

                      &:hover {
                        /**/
                      }
                    }
                  }

                  .bx-price {
                    /**/
                  }
                }
              }
            }

            .bx-soa-coupon {
              /**/

              .bx-soa-coupon-item {
                /**/

                .bx-soa-coupon-remove {
                  /**/

                  &:before,
                  &:after {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before,
                    &:after {
                      /**/
                    }
                  }
                }


              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Region
        #bx-soa-region {
          /**/

          &.bx-soa-section {
            /**/

            .alert {
              /**/
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .form-group {
              /**/

              .radio-inline {
                display: block;

                label {
                  /**/
                }

                &:first-child {
                  /**/
                }

                &.checked {

                  label {

                    &:before {
                      /**/
                    }

                    &:after {
                      /**/
                    }
                  }
                }

                label {
                  /**/

                  &:before {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before {
                      /**/
                    }
                  }
                }
              }

              &:after {
                /**/
              }

            }


          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Bottom
        .soa-bottom {
          /**/

          .soa-bottom-cols {
            flex-flow: column;

            .soa-bottom-cols-left {
              width: 100%;
              margin: 0 0 20px 0;
            }

            .soa-bottom-cols-right {
              width: 100%;
              margin: 0;
            }
          }

          .form {
            /**/

            .licence_block {
              /**/

              label {
                /**/

                &.license {
                  /**/

                  a {
                    /**/

                    &:hover {
                      /**/
                    }
                  }
                }
              }

              .error {
                /**/
              }
            }
          }

        }

        #bx-soa-orderSave {
          text-align: left;

          .btn-default {
            width: 100%;

            &:hover {
              /**/
            }
          }

        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Total
      #bx-soa-total {
        /**/

        .bx-soa-total-title {
          /**/
        }

        .bx-soa-cart-total {
          /**/

          .bx-soa-cart-total-line {
            /**/

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      #bx-soa-total-mobile {
        /**/

        .bx-soa-total-title {
          /**/
        }

        .bx-soa-cart-total {
          /**/

          .bx-soa-cart-total-line {
            /**/

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Pick up
      #bx-soa-pickup {
        /**/

        .bx-soa-pickup-list {
          /**/

          .bx-soa-pickup-l-item-detail {
            /**/
          }

          .bx-soa-pickup-l-item-btn {
            /**/

            .btn {
              /**/

              &.btn-default {
                /**/

                &:hover {
                  /**/
                }
              }
            }

          }
        }


      }
    }
  }
}

@media (max-width: 450px) {
  .sale-order-ajax-v1 {
    /**/

    #bx-soa-order {
      /**/

      // Personal -> Order -> Make (Sale order ajax) -> General
      input[type='text'],
      input[type='password'],
      input[type='tel'],
      input[type='email'],
      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      textarea.form-control {
        /**/

        &:focus {
          /**/
        }

        &::placeholder {
          /**/
        }

        &:focus::placeholder {
          /**/
        }
      }

      input[type="checkbox"].custom-checkbox {
        /**/
      }

      input[type='checkbox'].custom-checkbox {
        /**/

        & + label {
          /**/

          &:before {
            /**/
          }

          &:after {
            /**/
          }
        }


        &:not(:checked) {
          /**/

          & + label {
            /**/

            &:before {
              /**/
            }
          }
        }

        &:checked {
          /**/

          & + label {
            /**/

            &:after {
              /**/
            }
          }
        }

      }

      label.error {
        /**/
      }

      &.orderform--v1 {
        .bx-soa-pp-company-graf-container {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company.bx-selected
        .bx-soa-pp-company-graf-container::after {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company-graf-container:before {
          /**/
        }

        .bx-soa-pp-item-container .bx-soa-pp-company {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-company:not(:first-of-type) {
          /**/
        }

        .bx-soa-pp-item-container
        .bx-soa-pp-delivery-cost
        .bx-soa-pp-list-description {
          /**/
        }
      }

      .radio-inline.checked label:before,
      .radio-inline label:hover:before,
      .bx-soa-pp-company:hover .bx-soa-pp-company-graf-container:before,
      .bx-soa-pp-company.bx-selected .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-graf-container:before {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company.bx-selected
      .bx-soa-pp-company-graf-container:after {
        /**/
      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company:hover
      .bx-soa-pp-company-smalltitle,
      .bx-soa-more
      .bx-soa-more-btn
      .pull-left {
        /**/
      }

      .bx-soa-pp-company-description {

      }

      .bx-soa-pp-item-container
      .bx-soa-pp-company-smalltitle {
        /**/
      }

      div[class*=bx-sls] {
        /**/

        .form-control {
          /**/

          &.dropdown-block {
            /**/

            &:hover {
              /**/

              .bx-ui-sls-clear {
                /**/

                &:before {
                  /**/
                }

                &:after {
                  /**/
                }
              }

            }
          }


        }


        .dropdown-block {
          /**/

          &:hover {
            /**/

            .bx-ui-sls-clear {
              /**/

              &:before {
                /**/
              }

              &:after {
                /**/
              }
            }

          }


        }

        .bx-ui-sls-clear {
          /**/

          &:hover {
            /**/

            &:before {
              /**/
            }

            &:after {
              /**/
            }
          }
        }

        &.bx-sls {
          /**/

          .bx-ui-sls-container {
            /**/
          }
        }

      }

      .bx-soa-tooltip {
        /**/

        .tooltip-inner {
          /**/
        }
      }

      .bx-soa {
        /**/

        .bx-soa-section {
          /**/

          .bx-soa-section-title-container {
            /**/

            .bx-soa-section-title {
              /**/

              &:before {
                /**/
              }

              &.bx-soa-section-title-2 {
                /**/

                &:before {
                  /**/
                }
              }
            }
          }

          .bx-soa-section-content {
            /**/

            .row {
              /**/

              & > div {
                /**/
              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Properties
        #bx-soa-properties {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-properties-header {
              /**/

              .bx-soa-properties-header-cols {
                /**/

                .bx-soa-properties-header-cols-left {
                  /**/
                }

                .bx-soa-properties-header-cols-right {
                  /**/
                }
              }
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .form-group {
              /**/

              &.has-error {
                /**/
              }

              .soa-property-container {
                /**/

                &:focus,
                &:active {
                  /**/

                  label {
                    /**/
                  }
                }

                .form-control {
                  /**/

                  &.active {
                    /**/

                    + label {
                      /**/
                    }
                  }
                }
              }

              &:first-child {
                /**/
              }

              input[type='text'],
              input[type='password'],
              input[type='tel'],
              input[type='email'],
              textarea.form-control {
                /**/

                &::placeholder {
                  /**/
                }

                &:focus {
                  /**/

                  &::placeholder {
                    /**/
                  }

                  + label {
                    /**/
                  }
                }

                &:active {
                  /**/

                  + label {
                    /**/
                  }
                }

                & + label {
                  /**/

                  &:focus,
                  &:active {
                    /**/
                  }

                  span {
                    /**/
                  }
                }

                &.not-empty {
                  /**/

                  + label {
                    /**/
                  }
                }


              }

              textarea {
                /**/
              }
            }
          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Delivery
        #bx-soa-delivery {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }
          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Paysystems
        #bx-soa-paysystem {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/

              .row {
                /**/

                &.bx-soa-pp {
                  /**/
                }
              }
            }


          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Basket
        #bx-soa-basket {
          /**/

          &.bx-soa-section {
            /**/

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-pp-company-description {
              /**/

              a {
                /**/

                &:hover {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .bx-soa-item-table {
              /**/

              .bx-soa-item-tr {
                /**/

                &.bx-soa-item-tr-first {
                  /**/
                }

                .bx-soa-item-td {
                  /**/

                  .bx-soa-item-title {
                    /**/

                    a {
                      /**/

                      &:hover {
                        /**/
                      }
                    }
                  }

                  .bx-price {
                    /**/
                  }


                  &.bx-soa-item-properties {
                    width: 50% !important;
                  }
                }
              }
            }

            .bx-soa-coupon {
              /**/

              .bx-soa-coupon-item {
                /**/

                .bx-soa-coupon-remove {
                  /**/

                  &:before,
                  &:after {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before,
                    &:after {
                      /**/
                    }
                  }
                }


              }
            }

          }
        }

        // Personal -> Order -> Make (Sale order ajax) -> Region
        #bx-soa-region {
          /**/

          &.bx-soa-section {
            /**/

            .alert {
              /**/
            }

            .bx-soa-section-title-container {
              /**/

              .bx-soa-section-title {
                /**/

                &:before {
                  /**/
                }
              }
            }

            .bx-soa-section-content {
              /**/
            }

            .form-group {
              /**/

              .radio-inline {
                /**/

                label {
                  /**/
                }

                &:first-child {
                  /**/
                }

                &.checked {

                  label {

                    &:before {
                      /**/
                    }

                    &:after {
                      /**/
                    }
                  }
                }

                label {
                  /**/

                  &:before {
                    /**/
                  }

                  &:hover {
                    /**/

                    &:before {
                      /**/
                    }
                  }
                }
              }

              &:after {
                /**/
              }

            }


          }

        }

        // Personal -> Order -> Make (Sale order ajax) -> Bottom
        .soa-bottom {
          /**/

          .soa-bottom-cols {
            /**/

            .soa-bottom-cols-left {
              /**/
            }

            .soa-bottom-cols-right {
              /**/
            }
          }

          .form {
            /**/

            .licence_block {
              /**/

              label {
                /**/

                &.license {
                  /**/

                  a {
                    /**/

                    &:hover {
                      /**/
                    }
                  }
                }
              }

              .error {
                /**/
              }
            }
          }

        }

        #bx-soa-orderSave {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }

        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Total
      #bx-soa-total {
        /**/

        .bx-soa-total-title {
          /**/
        }

        .bx-soa-cart-total {
          /**/

          .bx-soa-cart-total-line {
            /**/

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      #bx-soa-total-mobile {
        /**/

        .bx-soa-total-title {
          /**/
        }

        .bx-soa-cart-total {
          /**/

          .bx-soa-cart-total-line {
            /**/

            &:before {
              /**/
            }

            .bx-soa-cart-t {
              /**/
            }

            .bx-soa-cart-d {
              /**/
            }

            &.bx-soa-cart-total-line-total {
              /**/

              .bx-soa-cart-t {
                /**/
              }

              .bx-soa-cart-d {
                /**/
              }
            }
          }
        }

        .bx-soa-cart-total-button-container {
          /**/

          .btn-default {
            /**/

            &:hover {
              /**/
            }
          }
        }
      }

      // Personal -> Order -> Make (Sale order ajax) -> Pick up
      #bx-soa-pickup {
        /**/

        .bx-soa-pickup-list {
          /**/

          .bx-soa-pickup-l-item-detail {
            /**/
          }

          .bx-soa-pickup-l-item-btn {
            /**/

            .btn {
              /**/

              &.btn-default {
                /**/

                &:hover {
                  /**/
                }
              }
            }

          }
        }


      }
    }
  }
}

// Personal -> Order -> Make (Sale order ajax) -> Confirm
.sale-order-ajax-v1-confirm {
  position: relative;
  padding: 105px 0 0 0;
  margin: 0 auto 30px;
  max-width: 690px;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    margin: 0 0 0 -35px;
    width: 70px;
    height: 70px;
    background: url(../../images/order/svg/catalog/success.svg) 0 0 no-repeat;

    background-color: #aad155 !important;
  }

  .sale-order-ajax-v1-confirm-title {
    text-align: center;
    font-weight: 700;
    margin: 0 0 20px 0;
  }

  .sale-order-ajax-v1-confirm-text {
    text-align: center;
    margin: 0 0 30px 0;
  }

  .sale-order-ajax-v1-confirm-buttons-wrapper {
    /**/
  }

  .sale-order-ajax-v1-confirm-buttons {
    text-align: center;

    .btn-default {
      background: #a8d036 !important;
      //background: #a8d036;
      //font-size: 14px !important;
      font-size: 14px;
      text-transform: uppercase;
      border: none;
      border-radius: 0;
      padding: 12px 32px;
      height: auto;
      line-height: 19px;
      //margin: 0 !important;
      margin: 0;
      white-space: normal;
      vertical-align: top;
      font-weight: 400;
      color: #fff !important;
      //color: #fff;
      display: inline-block;
      outline: none;

      &:active {
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
      }

      &:hover {
        /**/
      }
    }
  }


}