// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$cart-icon-name: 'cart-icon';
$cart-icon-x: 40px;
$cart-icon-y: 0px;
$cart-icon-offset-x: -40px;
$cart-icon-offset-y: 0px;
$cart-icon-width: 39px;
$cart-icon-height: 36px;
$cart-icon-total-width: 116px;
$cart-icon-total-height: 72px;
$cart-icon-image: '../img/sprites/img/dest/sprite.png';
$cart-icon: (40px, 0px, -40px, 0px, 39px, 36px, 116px, 72px, '../img/sprites/img/dest/sprite.png', 'cart-icon', );
$delivery-icon-name: 'delivery-icon';
$delivery-icon-x: 0px;
$delivery-icon-y: 37px;
$delivery-icon-offset-x: 0px;
$delivery-icon-offset-y: -37px;
$delivery-icon-width: 40px;
$delivery-icon-height: 35px;
$delivery-icon-total-width: 116px;
$delivery-icon-total-height: 72px;
$delivery-icon-image: '../img/sprites/img/dest/sprite.png';
$delivery-icon: (0px, 37px, 0px, -37px, 40px, 35px, 116px, 72px, '../img/sprites/img/dest/sprite.png', 'delivery-icon', );
$user-icon-name: 'user-icon';
$user-icon-x: 79px;
$user-icon-y: 0px;
$user-icon-offset-x: -79px;
$user-icon-offset-y: 0px;
$user-icon-width: 37px;
$user-icon-height: 37px;
$user-icon-total-width: 116px;
$user-icon-total-height: 72px;
$user-icon-image: '../img/sprites/img/dest/sprite.png';
$user-icon: (79px, 0px, -79px, 0px, 37px, 37px, 116px, 72px, '../img/sprites/img/dest/sprite.png', 'user-icon', );
$wallet-icon-name: 'wallet-icon';
$wallet-icon-x: 0px;
$wallet-icon-y: 0px;
$wallet-icon-offset-x: 0px;
$wallet-icon-offset-y: 0px;
$wallet-icon-width: 40px;
$wallet-icon-height: 37px;
$wallet-icon-total-width: 116px;
$wallet-icon-total-height: 72px;
$wallet-icon-image: '../img/sprites/img/dest/sprite.png';
$wallet-icon: (0px, 0px, 0px, 0px, 40px, 37px, 116px, 72px, '../img/sprites/img/dest/sprite.png', 'wallet-icon', );
$spritesheet-width: 116px;
$spritesheet-height: 72px;
$spritesheet-image: '../img/sprites/img/dest/sprite.png';
$spritesheet-sprites: ($cart-icon, $delivery-icon, $user-icon, $wallet-icon, );
$spritesheet: (116px, 72px, '../img/sprites/img/dest/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
